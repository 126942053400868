import './App.css';
import Design1 from "./designs/design-1";
import Design2 from "./designs/design-2";
import Design3 from "./designs/design-3";

function App() {
  return (
    <div>
      <Design1 />
      <Design2 />
      <Design3 />      
      <br />
      <div className="footer-line"></div>
      <div className="design-main-page-footer">Copyright &copy; {new Date().getFullYear()} <strong>Atta ul Hyee</strong></div>
    </div>
  );
}

export default App;
