import React from 'react';
import "./design-2.css";

// rsb- => right side bar 

function Design2() {
    return (
        <div>
            <div className="design-header">Design 2</div>
            <div className="d2-container">
                <div className="d2-header">Header</div>
                <div className="d2-box">Box 1</div>
                <div className="d2-box">Box 2</div>
                <div className="d2-box">Box 3</div>
                <div>Box 4</div>
                <div className="d2-main">Main</div>
                <div className="d2-side-bar">Sidebar</div>
                <div className="d2-footer">Footer</div>
            </div>
        </div>
    );
}

export default Design2;