import React from 'react';
import "./design-3.css";

function Design3() {
    return (
    <div>
        <div className="design-header">Design 3</div>
        <div className="d3-container">
            <div className="d3-header">Header</div>
            <div className="d3-box">Box 1</div>
            <div className="d3-box">Box 2</div>
            <div className="d3-side-bar-left">Sidebar Left</div>
            <div className="d3-main">MainContent</div>
            <div className="d3-side-bar-right">Sidebar Right</div>
            <div className="d3-footer">Footer</div>
        </div>
    </div>
    );
}

export default Design3;
