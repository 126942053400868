import React from 'react';
import "./design-1.css";

function Design1() {
    return (
        <div>
            <div className="design-header">Design 1</div>
            <div className="d1-container">
                <div className="d1-header">Header</div>
                <div className="d1-box">Box 1</div>
                <div className="d1-box">Box 2</div>
                <div className="d1-main">Main</div>
                <div className="d1-side-bar">Sidebar</div>
                <div className="d1-footer">Footer</div>
            </div>
        </div>
    );
}

export default Design1;